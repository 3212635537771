<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { getIdFromIso, getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { getMenuGroups, getTitle } from '@/helpers/metaTags'
  import { navigateWithReset } from '@/helpers/navigateWithReset'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import Pagination from '@/components/ui/Pagination.svelte'

  import { Bread, SelectTask, UnknownRecord } from '@/definitions/langoid'
  import { lastLearningLanguage, menuLanguage, taskTranslateFromLanguage, taskTranslateToLanguage, userStorredConfig } from '@/store'

  export let iso: string,
    type = '',
    id = '1',
    target: string = '' // language we are translating to

  taskTranslateToLanguage.set($userStorredConfig.id_lang_learning)
  if ($taskTranslateToLanguage !== $userStorredConfig.id_lang_native) {
    lastLearningLanguage.set($taskTranslateToLanguage)
  }
  $taskTranslateToLanguage === $userStorredConfig.id_lang_native ? taskTranslateFromLanguage.set($lastLearningLanguage) : taskTranslateFromLanguage.set($userStorredConfig.id_lang_native)

  let isMyNative = false
  let tasks: SelectTask[] = []
  const perPage = 10
  let currentPage = parseInt(id, 10)
  let totalPages = 1
  const filter = 'active'
  let fromLangId: number = target ? getIdFromIso(target) : $taskTranslateFromLanguage
  let toLangId: number = $taskTranslateToLanguage
  let loaded = false
  let breads: Bread[] = []

  const handleData = (data: UnknownRecord) => {
    isMyNative = getIsoFromId($userStorredConfig.id_lang_native) === iso
    totalPages = Math.ceil(data.count / perPage)
    tasks = data.tasks
  }

  $: if (loaded) {
    fetchData('tasks/select', { filter, from: currentPage || 1, group: type, target: fromLangId }).then((data) => {
      handleData(data)
    })
  }

  const unsubscribe = menuLanguage.subscribe(val => {
    taskTranslateToLanguage.set(getIdFromIso(val))
    toLangId = getIdFromIso(val) || $userStorredConfig.id_lang_learning
  })

  $: if (filter) {
    currentPage = parseInt(id, 10)
  }

  const handleFromLangChange = (lang: number) => {
    if (lang === toLangId) {
      notifier.error($_('tasksPage.sameLang'))
      return false
    }
    fromLangId = lang
    taskTranslateFromLanguage.set(lang)
    navigateWithReset(`/${getIsoFromId(toLangId)}/task/${type}/select/1/${getIsoFromId(lang)}`)
    return true
  }
  const handleToLangChange = (lang: number) => {
    if (lang === fromLangId) {
      notifier.error($_('tasksPage.sameLang'))
      return false
    }

    toLangId = lang
    if (fromLangId === 0) {
      navigateWithReset(`/${getIsoFromId(lang)}/task/${type}/select/1/${getIsoFromId($userStorredConfig.id_lang_native)}`)
    } else {
      navigateWithReset(`/${getIsoFromId(lang)}/task/${type}/select/1/${getIsoFromId(fromLangId)}`)
    }
    userStorredConfig.setKey('id_lang_learning', lang)

    return true
  }

  const swapLangs = () => {
    if (fromLangId === 0 || toLangId === 0) {
      notifier.error($_('error.youMustChooseLanguage'))
      return false
    }
    [fromLangId, toLangId] = [toLangId, fromLangId]
    userStorredConfig.setKey('id_lang_learning', toLangId)
    taskTranslateFromLanguage.set(fromLangId)
    navigateWithReset(`/${getIsoFromId(toLangId)}/task/${type}/select/1/${getIsoFromId(fromLangId)}`)
  }

  function goTo (page: number): void {
    if (target) {
      navigate(`/${iso}/task/${type}/select/${page}/${target}`)
    } else {
      navigate(`/${iso}/task/${type}/select/${page}`)
    }
  }

  onMount(() => {
    loaded = true
  })
  const pageChange = (event: CustomEvent) => {
    goTo(event.detail)
  }
  let title = ''
  $:if (type) {
    if (type === 'story') title = $_('tasksPage.newStory')
    else if (type === 'translate') title = $_('tasksPage.newTranslate')
    else if (type === 'read') title = $_('tasksPage.newRead')
    else if (type === 'listen') title = $_('tasksPage.newListen')

    const tmp = getMenuGroups(location.pathname)
    const text = getTitle(tmp.menuGroups, tmp.locationGroup)
    breads = [
      { mobileUrl: `/${iso}/task`, text: '', url: `/${iso}` },
      { text: $_(text) }
    ]
  }

  onDestroy(() => {
    unsubscribe()
  })
</script>

<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{title}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='exercises' />
  </div>
</Breadcrumb>
<div class='_gap24 -unwrapMobile'>
  <div class='select-header'>
    <h2 class='_desktopOnly'>{title}</h2>
    {#if type === 'translate'}
      <div class='task-swap-wrapper'>
        <div class='select-fromto'>
          <p class='fromTo'>{$_('tools.from')}:</p>
          <LanguageSelect onChange={handleFromLangChange} type='my' bind:selectedLanguage={fromLangId} />
        </div>
        <FakeButton
          onClick={swapLangs}
        >
          <Icon icon='Swap' weight='regular' />
        </FakeButton>
        <div class='select-fromto'>
          <p class='fromTo'>{$_('tools.to')}:</p>
          <LanguageSelect onChange={handleToLangChange} type='my' bind:selectedLanguage={toLangId} />
        </div>
      </div>
    {/if}
  </div>

  <div class='tasks-wrapper'>
    <div class='controls-menu'>
      {#if isMyNative}
        <h2>{$_('tasksPage.nativeStories')}</h2>
      {/if}
    </div>
    {#if Object.keys(tasks).length > 0 && fromLangId !== 0}
      <div class='tasks-list'>
        {#each tasks as task}
          <div
            class='single-task'
            role='link'
            tabindex={0}
            on:click={() => {
            navigate(`/${iso}/task/${type}/create/${task.id}`)
          }}
            on:keypress={() => {}}
          >
            <p class='content'>{type === 'listen' ? $_('tasksPage.playAudio') : task.text_content}</p>
            <div class='avatars-holder'>
              <UserAvatar
                avatarVariant='smallAvatar'
                date={task.created_at}
                dateVariant='withDate'
                metaDataVariant='right'
                user={{ avatar: task.avatar, id:task.id_user, level: task.level, nick: task.nick }}
              />
              {#if task.used}
                <span class='used'>{$_('tasksPage.used')}</span>
              {/if}
            </div>
          </div>
          <hr />
        {/each}
      </div>
      <Pagination count={totalPages} current={currentPage} on:change={pageChange} />
    {:else}
      <EmptyState>
        <div>{$_('tasksPage.noTasks')}</div>
      </EmptyState>
    {/if}
  </div>
</div>

<style lang='scss'>
  .select-header {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
  }

  .tasks-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .controls-menu {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
  }

  .tasks-list {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > hr:last-child {
      display: none;
    }
  }

  .single-task {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    cursor: pointer;

    &:hover {
      > .content {
        text-decoration: underline;
      }
    }

    > .content {
      font: var(--Medium-400);
    }
  }

  .avatars-holder {
    display: flex;
    justify-content: space-between;
  }

  .task-swap-wrapper {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-between;
    width: fit-content;

    > :global(.fake-button) {
      margin-top: 1.6rem;
    }
  }

  @media (max-width: 768px) {
    .task-swap-wrapper {
      flex-direction: column;
      gap: 0;
      width: 100%;

      > :global(.fake-button) {
        margin: 0.4rem 0;
      }
    }

    .select-fromto {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      width: 100%;
    }
  }
</style>
